<template lang="pug">
sidebar-accordion(
  v-if="isSetBackgroundType"
  ref="accordion"
  :title="$t(label)"
  :opened="isOpen"
  :addDeviceSelector="editMobile"
  :canRemainOpen="true"
  :leftSidebar="leftSidebar"
)
  template(v-if="selectedImagePosition === 'crop'")
    om-color-input(
      :label="imageType[selectedImagePosition].text"
      :property="getPath(imageType[selectedImagePosition].property)"
      :typeOfComponent="typeOfComponent"
      :alpha="transparentOption"
      data-track-property="setting:fill-open|change:website.overlay.fill.open"
    )
  template(v-else)
    om-color-input(
      :label="colorText[backgroundType].text"
      :property="getPath(colorText[backgroundType].property)"
      :typeOfComponent="typeOfComponent"
      :alpha="transparentOption"
      data-track-property="setting:fill-open|change:website.overlay.fill.open"
      :editMobile="typeOfComponent === 'product' ? true : false"
    )
  template(v-if="typeOfComponent === 'button' && !mobilePreview")
    om-color-input(
      :label="colorTextForHover[hoverType].text"
      :property="getPath(colorTextForHover[hoverType].property)"
      typeOfComponent="button"
      :alpha="transparentOption"
    )
  template(v-if="gradientType === 'radial' && backgroundType === 'gradient'")
    om-dropdown-input(
      label="gradientCenter"
      :property="getPath('background.gradientCenter')"
      :items="radialDirection"
      :typeOfComponent="typeOfComponent"
    )
  template(v-if="gradientType === 'linear' && backgroundType === 'gradient'")
    .mt-4
      om-range-input(
        label="gradientDirection"
        :property="getPath('background.linearDirection')"
        :min="0"
        :max="360"
        :step="1"
        unit="°"
        :typeOfComponent="typeOfComponent"
      )
  template(v-if="animationIsVisible")
    .animation-box(:style="{ 'margin-left': 0 }") {{ $t('editorBeta.overlayAnimation') }}
    om-dropdown-input(
      label="type"
      :property="getPath('animation.type')"
      :items="overlay.animation.types"
      :typeOfComponent="typeOfComponent"
    )
    om-dropdown-input(
      v-if="isAnimationConfetti"
      label="mode"
      :property="getPath('animation.mode')"
      :items="overlay.animation.modes"
      :typeOfComponent="typeOfComponent"
    )
    om-dropdown-input(
      label="page"
      :property="getPath('animation.page')"
      :items="choosablePages"
      :typeOfComponent="typeOfComponent"
    )
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import UploadArea from '../../UploadArea.vue';

  const globalSubpaths = ['selectedPage', 'globalStyle.overlay', 'globalStyle.tab', 'subElements'];
  const backgroundColorProp = 'background.color';
  const hoverColorProp = 'hover.color';

  export default {
    components: { UploadArea },
    mixins: [itemMixin, themeColorsMixin],

    props: {
      label: { type: String, default: 'backgroundFill' },
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: '' },
      isOpen: { type: Boolean, default: false },
      typeOfComponent: { type: String, default: 'global' },
      transparentOption: { type: Boolean, default: true },
      imageProperties: { type: Boolean, default: true },
      saveImageUrl: { type: Boolean, default: false },
      blacklist: { type: Array, default: () => [] },
      isLeftSide: { type: Boolean, default: true },
      canRemainOpen: { type: Boolean, default: false },
      leftSidebar: { type: Boolean, default: false },
    },
    data() {
      return {
        imageType: {
          crop: {
            property: backgroundColorProp,
            text: 'imageCropped',
          },
        },
        colorText: {
          transparent: {
            property: backgroundColorProp,
            text: 'none',
          },
          'solid-fill': {
            property: backgroundColorProp,
            text: 'solidColor',
          },
          gradient: {
            property: 'background.color2',
            text: 'gradient',
          },
          image: {
            property: backgroundColorProp,
            text: 'image',
          },
        },
        colorTextForHover: {
          transparent: {
            property: hoverColorProp,
            text: 'hoverNone',
          },
          'solid-fill': {
            property: hoverColorProp,
            text: 'hoverSolidFill',
          },
          gradient: {
            property: 'hover.color2',
            text: 'hoverGradient',
          },
          image: {
            property: hoverColorProp,
            text: 'hoverImage',
          },
        },
        radialDirection: [
          { key: 'center', value: 'circle at center' },
          { key: 'topLeft', value: 'to top left' },
          { key: 'top', value: 'to top' },
          { key: 'topRight', value: 'to top right' },
          { key: 'right', value: 'to right' },
          { key: 'bottomRight', value: 'to bottom right' },
          { key: 'bottom', value: 'to bottom' },
          { key: 'bottomLeft', value: 'to bottom left' },
          { key: 'left', value: 'to left' },
        ],
        imagePosition: [
          { key: 'original', value: 'full' },
          { key: 'contain', value: 'contain' },
          { key: 'cover', value: 'cover' },
          { key: 'stretch', value: '100% 100%' },
        ],
        imageAlign: [
          { key: 'top', value: 'top' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'bottom' },
        ],
        imageHorizontalAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
        imageRepeat: [
          { key: 'noRepeat', value: 'no-repeat' },
          { key: 'repeat', value: 'repeat' },
          { key: 'repeatHorizontally', value: 'repeat-x' },
          { key: 'repeatVertically', value: 'repeat-y' },
        ],
        options: {
          acceptedFileTypes: ['image/*'],
          url: 'http://localhost:3000/fileupload',
          clickable: false,
        },
        overlay: {
          animation: {
            types: [
              { key: 'none', value: null },
              { key: 'confetti', value: 'confetti' },
              { key: 'fireworks', value: 'fireworks' },
            ],
            modes: [
              { key: 'cannon', value: 'cannon' },
              { key: 'fireworks', value: 'fireworks' },
            ],
          },
        },
      };
    },
    computed: {
      ...mapGetters(['nestedAccess', 'pages', 'isEmbedded', 'isNano', 'isSidebar']),
      ...mapState(['globalStyle', 'images', 'mobilePreview']),
      isSetBackgroundType() {
        return typeof this.backgroundType !== 'symbol';
      },
      backgroundType() {
        return this.getProperty('background', 'type');
      },
      hoverType() {
        return this.getProperty('hover', 'type');
      },
      selectedImagePosition() {
        const path = this.getPath('background.imagePosition');
        return this.getValueOf(path);
      },
      savedImageUrl() {
        const path = this.getPath('background.imageUrl');
        return this.getValueOf(path);
      },
      imageId() {
        return this.getProperty('background', 'imageId');
      },
      backgroundColor() {
        return this.getColor('background', 'color');
      },
      backgroundColor2() {
        return this.getColor('background', 'color2', false);
      },
      hoverColor() {
        return this.getColor('hover', 'color');
      },
      hoverColor2() {
        return this.getColor('hover', 'color2', false);
      },
      linearDirection() {
        const path = this.getPath('background.linearDirection');
        return this.getValueOf(path, 0);
      },
      gradientCenter() {
        const path = this.getPath('background.gradientCenter');
        return this.getValueOf(path);
      },
      gradientType() {
        const path = this.getPath('background.gradientType');
        return this.getValueOf(path) || 'linear';
      },
      solidBackground() {
        return { background: this.backgroundColor };
      },
      gradientBackground() {
        const color1 = this.backgroundColor;
        const color2 = this.backgroundColor2;
        const gradientType =
          this.gradientType === 'linear' ? `${this.linearDirection || 0}deg` : this.gradientCenter;
        return this.getGradient(color1, color2, gradientType);
      },
      hoverLinearDirection() {
        const path = this.getPath('hover.linearDirection');
        return this.getValueOf(path, 0);
      },
      hoverGradientCenter() {
        const path = this.getPath('hover.gradientCenter');
        return this.getValueOf(path);
      },
      hoverGradientType() {
        const path = this.getPath('hover.gradientType');
        return this.getValueOf(path) || 'linear';
      },
      hoverSolidBackground() {
        return { background: this.hoverColor };
      },
      gradientHover() {
        const color1 = this.hoverColor;
        const color2 = this.hoverColor2;
        const gradientType =
          this.gradientType === 'linear'
            ? `${this.hoverLinearDirection || 0}deg`
            : this.hoverGradientCenter;
        return this.getGradient(color1, color2, gradientType);
      },
      backgroundImage() {
        if (this.imageId && this.imageId.length > 0) {
          const image = this.images.find((i) => i._id === this.imageId);
          const url = image ? image.url : this.savedImageUrl;
          return url;
        }

        return undefined;
      },
      backgroundStyle() {
        // TODO - GLOBAL IMAGE
        const style = {
          width: '100%',
          height: '100%',
        };
        if (this.imageId && this.imageId.length > 0) {
          const image = this.images.find((i) => i._id === this.imageId);
          const url = image ? image.url : this.savedImageUrl;
          style.background = `url(${url || ''}) center center / contain no-repeat`;
        }

        return style;
      },
      choosablePages() {
        const pages = this.pages || [];
        const choosablePages = [{ key: 'allPages', value: -1 }];
        for (let i = 0; i < pages.length; i++) {
          const key =
            this.pages[i] && this.pages[i].data && this.pages[i].data.title
              ? this.pages[i].data.title
              : i + 1;
          choosablePages.push({ key, value: i });
        }
        return choosablePages;
      },
      isAnimationConfetti() {
        return (
          this.globalStyle.overlay.animation &&
          this.globalStyle.overlay.animation.type === 'confetti'
        );
      },
      animationIsVisible() {
        return (
          !this.isEmbedded && !this.isNano && !this.isSidebar && this.subPath.includes('overlay')
        );
      },
    },
    created() {
      this.$bus.$on('toggleWebsiteOverlay', this.open);
    },
    beforeDestroy() {
      this.$bus.$off('toggleWebsiteOverlay', this.open);
    },
    methods: {
      ...mapMutations([
        'updateStyle',
        'showImageManager',
        'setImageManagerDestination',
        'setSelectedPath',
      ]),
      open() {
        this.$children[0].open();
      },
      close() {
        this.$children[0].close();
      },
      imageManager() {
        this.setImageManagerDestination({
          targetAttr: this.getTargetAttr(),
          addElement: false,
          background: true,
          saveImageUrl: this.saveImageUrl,
          extBlacklist: this.blacklist,
          editedDevice: this.editedDeviceType,
        });
        this.setSelectedPath(this.subPath);
        this.showImageManager();
      },
      getTargetAttr() {
        if (this.subPath.includes('subElements')) {
          return `${this.propsProperty}.${this.subPath}`;
        }

        return this.propsProperty;
      },
      setBackgroundType(value) {
        this.setValueOf(this.getPath(`${this.propsProperty}.type`), value);
        window.om.bus.$emit('userInputChange', {
          property: this.getPath(`${this.propsProperty}.type`),
          value,
        });

        if (!this.isGlobalSubPath() && this.editedDeviceType === 'mobile' && this.editMobile) {
          this.setValueOf(`mobile.${this.propsProperty}.type`, value);
          window.om.bus.$emit('userInputChange', {
            property: `mobile.${this.propsProperty}.type`,
            value,
          });
        }
      },
      triggerBrowse() {
        this.$refs.uploader.triggerBrowseFiles();
      },
      getPath(path) {
        let result = `${this.editedDeviceType}.${path}`;

        if (this.subPath && this.subPath === 'selectedPage') {
          result = `${this.subPath}.${result}`;
        } else if (this.subPath) {
          result = `${this.subPath}.${path}`;
        }

        return result;
      },
      isGlobalSubPath() {
        return globalSubpaths.some((s) => this.subPath.indexOf(s) !== -1);
      },
      getProperty(property, subPath) {
        let value;
        let mobileValue;

        if (this.isGlobalSubPath()) {
          const desktopPath = this.getPath(`${property}.${subPath}`).replace('mobile', 'desktop');
          const mobilePath = desktopPath.replace('desktop', 'mobile');
          value = this.getValueOf(desktopPath);
          mobileValue = this.getValueOf(mobilePath);
        } else {
          value = this.getValueOf(`desktop.${property}.${subPath}`);
          mobileValue = this.getValueOf(`mobile.${property}.${subPath}`);
        }

        if (this.mobilePreview && mobileValue) {
          value = mobileValue;
        }

        return value;
      },
      getColor(type, property, main = true) {
        const mainColor = this.getColors[0];
        const firstSecondary = this.getColors[1];
        const path = this.getPath(`${type}.${property}`);
        const color = this.getValueOf(path, main ? mainColor : firstSecondary);
        return color > -1 ? this.getColors[color] : color;
      },
      getGradient(color1, color2, gradientType) {
        return {
          'background-image': `linear-gradient(${gradientType},${color1},${color2})`,
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  .animation-box
    font-size: 12px
    color: #505763
    margin: 16px 0px
    line-height: 24px
    font-weight: bold
</style>
