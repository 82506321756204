import Notification from './components/Notification.vue';
import { events } from './events';

const notificationType = (params) => {
  if (params.type === 'error') return 'danger';

  return params.type ?? 'danger';
};

const OmToast = {
  install(Vue, args = {}) {
    if (this.installed) {
      return;
    }

    this.installed = true;
    this.params = args;

    Vue.component(args.componentName || 'om-notification', Notification);

    const OmNotification = (params) => {
      if (typeof params === 'string') {
        params = { title: '', text: params };
      }

      if (typeof params === 'object') {
        // switched the type and color parameter to prevent all $notify rewrite
        events.$emit('add', {
          color: notificationType(params),
          type: params.toastType || 'full',
          closable: params.closable || false,
          width: params.width || 248,
          ignoreDuplicates: params.ignoreDuplicates || true,
          duration: params.duration || 6000,
          text: params.text,
          title: params.title,
        });
      }
    };

    OmNotification.close = function (id) {
      events.$emit('close', id);
    };

    const name = args.name || 'OmNotification';

    Vue.prototype.$notify = OmNotification;
    Vue[name] = OmNotification;
  },
};

export default OmToast;
